html {
  box-sizing: border-box;
  font-size: 16px;
  overflow: hidden; }

body {
  overflow: hidden;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"; }

app {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  overflow: hidden;
  /*  > div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
*/ }
  app #content-component {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto; }

hr {
  width: 100%; }

form {
  display: flex;
  flex-direction: column; }

input {
  display: inline-block;
  font-weight: 400;
  padding: 5px 5px;
  border: solid 1px #c98989;
  border-radius: 5px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  overflow: visible;
  margin: 4px; }
  input:focus {
    outline: none; }

textarea {
  display: inline-block;
  font-weight: 400;
  padding: 5px 5px;
  border: solid 1px #c98989;
  border-radius: 5px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  overflow: visible;
  margin: 4px; }
  textarea:focus {
    outline: none; }

.buttons {
  display: flex;
  flex-direction: row;
  margin: 4px;
  margin-top: 24px;
  flex-wrap: wrap;
  justify-content: center; }

.grow {
  flex-grow: 1; }

.error {
  color: red;
  fill: red; }

.flex-row {
  display: flex;
  flex-direction: row; }

.flex-col {
  display: flex;
  flex-direction: column; }

.green-text {
  color: lightseagreen;
  fill: lightseagreen; }

.darkgreen-text {
  color: green;
  fill: green; }

.red-text {
  color: red;
  fill: red; }

.darkred-text {
  color: darkred;
  fill: darkred; }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0; }

.pad-8 {
  padding: 0px 8px; }

.h5, h5 {
  font-size: 1.25rem; }

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2; }

table {
  display: table;
  border-collapse: collapse;
  box-sizing: border-box;
  text-indent: initial;
  background-color: transparent;
  width: 100%;
  max-width: 100%;
  padding: 4px;
  border-spacing: 0;
  margin-bottom: 1rem; }
  table thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
    text-align: left;
    border-bottom: 1px solid #000; }
  table tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit; }
  table.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05); }

.orderedProduct {
  background-color: lawngreen;
  font-weight: bold; }

.critical {
  color: #ff0000; }

.warning {
  color: #ffa319; }

.idle {
  color: #05ad05; }

.normal {
  color: #000000; }

* {
  scrollbar-width: thin;
  scrollbar-color: #7f0000 #f0f0f0; }

::-webkit-scrollbar {
  width: 7px;
  height: 7px; }

::-webkit-scrollbar-track {
  background-color: #f0f0f0; }

::-webkit-scrollbar-thumb {
  width: 7px;
  height: 7px;
  background-color: #0a52bd;
  border-radius: 3px; }

#body-section > div:hover::-webkit-scrollbar-thumb {
  background-color: #9f0000; }

bp-header {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: #0e4463;
  padding: 10px;
  height: 54px; }

bp-header-link {
  display: inline-flex;
  align-items: center;
  text-align: left;
  font-size: 16px;
  margin: 4px;
  padding-right: 32px;
  transition: color 0.15s ease-out, box-shadow 0.15s ease-out;
  color: white; }
  bp-header-link.active {
    color: steelblue; }
    bp-header-link.active svg {
      fill: steelblue; }
  bp-header-link div {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    fill: white;
    transition: fill 0.25s ease 0s; }
    bp-header-link div:hover {
      color: red;
      text-decoration: none;
      transition: fill 0.05s ease 0s; }
      bp-header-link div:hover svg {
        fill: red; }
  bp-header-link .link-icon svg {
    width: 24px;
    height: 24px; }
  bp-header-link .link-label {
    font-size: 13px;
    white-space: nowrap; }

#body-section {
  display: flex;
  overflow: hidden;
  flex-direction: row;
  flex-grow: 1; }
  #body-section > div {
    flex-grow: 1;
    padding: 24px;
    overflow-y: auto; }

bp-sidebar {
  flex-direction: column;
  display: flex;
  padding-top: 24px;
  width: 222px;
  flex-grow: 0;
  border-right: 1px solid #bfbfbf;
  flex-shrink: 0;
  background-color: #fff;
  align-items: flex-end;
  overflow-y: auto; }

bp-sidebar-link > div {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  margin: 4px 0;
  padding: 1px;
  padding-right: 8px;
  cursor: pointer;
  background-color: #f9f9f9;
  transition: background-color 0.15s ease-out, box-shadow 0.15s ease-out;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
  width: 200px;
  height: 50px;
  color: black; }
  bp-sidebar-link > div:hover {
    background-color: #f0f0f0; }

bp-sidebar-link .icon {
  width: 24px;
  height: 24px;
  margin-left: 6px;
  margin-right: 6px; }

bp-sidebar-link .label {
  white-space: nowrap; }

bp-sidebar-link .description {
  font-size: 12px;
  color: #999;
  white-space: nowrap; }

bp-sidebar-link .text {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-right: 8px; }

bp-footer {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 1;
  background-color: #f2f2f2;
  border-top: 1px solid #bfbfbf;
  padding: 5px; }
  bp-footer a {
    font-size: 14px;
    color: black; }

.product-image {
  width: 100px; }

.voided {
  text-decoration: line-through; }

.html-display-box {
  border: 1px solid black;
  padding: 10px;
  margin-top: 20px;
  filter: drop-shadow(10px 10px 10px #0e4463);
  background: white; }

.activeRow {
  background-color: lightsteelblue; }

.emailPreview {
  border: 1px solid black;
  padding: 12px;
  margin-left: 24px;
  filter: drop-shadow(10px 10px 10px #0e4463); }

tbody.board-items {
  font-size: 18px; }

bp-time-date .outer-box {
  display: flex;
  flex-direction: column;
  text-align: center;
  /*width: 110px;
    height: 74px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 8px;*/ }

bp-time-date .date-label {
  font-size: 0.8em;
  opacity: 0.8; }

bp-time-date .time-text {
  text-align: center;
  font-size: 0.9em; }

bp-time-date .date-text {
  text-align: center;
  font-size: 0.7em; }

#bp-dialog-overlay, bp-confirm-dialog, bp-dialog {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000082;
  position: absolute;
  z-index: 10;
  /*}

bp-confirm-dialog, bp-dialog {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000082;
  position: absolute;
  z-index: 10;
*/ }
  #bp-dialog-overlay #bp-dialog, bp-confirm-dialog #bp-dialog, bp-dialog #bp-dialog {
    display: flex;
    flex-direction: column;
    max-width: 640px;
    min-width: 320px;
    margin: auto;
    height: 100%; }
    #bp-dialog-overlay #bp-dialog .header, bp-confirm-dialog #bp-dialog .header, bp-dialog #bp-dialog .header {
      flex-grow: 3; }
    #bp-dialog-overlay #bp-dialog .footer, bp-confirm-dialog #bp-dialog .footer, bp-dialog #bp-dialog .footer {
      flex-grow: 5; }
    #bp-dialog-overlay #bp-dialog .dialog-body, bp-confirm-dialog #bp-dialog .dialog-body, bp-dialog #bp-dialog .dialog-body {
      background-color: white;
      overflow: scroll; }
      #bp-dialog-overlay #bp-dialog .dialog-body .title, bp-confirm-dialog #bp-dialog .dialog-body .title, bp-dialog #bp-dialog .dialog-body .title {
        color: white;
        fill: white;
        background-color: steelblue;
        font-weight: 700;
        min-width: 180px;
        font-size: 16px;
        padding: 8px; }
      #bp-dialog-overlay #bp-dialog .dialog-body .content, bp-confirm-dialog #bp-dialog .dialog-body .content, bp-dialog #bp-dialog .dialog-body .content {
        padding: 8px; }
        #bp-dialog-overlay #bp-dialog .dialog-body .content .fields, bp-confirm-dialog #bp-dialog .dialog-body .content .fields, bp-dialog #bp-dialog .dialog-body .content .fields {
          margin-top: 8px; }
          #bp-dialog-overlay #bp-dialog .dialog-body .content .fields .field, bp-confirm-dialog #bp-dialog .dialog-body .content .fields .field, bp-dialog #bp-dialog .dialog-body .content .fields .field {
            margin-top: 8px;
            margin-bottom: 16px; }
            #bp-dialog-overlay #bp-dialog .dialog-body .content .fields .field input, #bp-dialog-overlay #bp-dialog .dialog-body .content .fields .field select, bp-confirm-dialog #bp-dialog .dialog-body .content .fields .field input, bp-confirm-dialog #bp-dialog .dialog-body .content .fields .field select, bp-dialog #bp-dialog .dialog-body .content .fields .field input, bp-dialog #bp-dialog .dialog-body .content .fields .field select {
              width: 98%;
              padding: 4px;
              border-radius: 4px;
              border: 1px solid #a8c4ff;
              margin-top: 4px; }
            #bp-dialog-overlay #bp-dialog .dialog-body .content .fields .field select, bp-confirm-dialog #bp-dialog .dialog-body .content .fields .field select, bp-dialog #bp-dialog .dialog-body .content .fields .field select {
              padding: 6px; }
            #bp-dialog-overlay #bp-dialog .dialog-body .content .fields .field input[type='checkbox'], bp-confirm-dialog #bp-dialog .dialog-body .content .fields .field input[type='checkbox'], bp-dialog #bp-dialog .dialog-body .content .fields .field input[type='checkbox'] {
              width: 60px; }
            #bp-dialog-overlay #bp-dialog .dialog-body .content .fields .field span.checkbox, bp-confirm-dialog #bp-dialog .dialog-body .content .fields .field span.checkbox, bp-dialog #bp-dialog .dialog-body .content .fields .field span.checkbox {
              width: 100%;
              display: inline-flex; }
            #bp-dialog-overlay #bp-dialog .dialog-body .content .fields .field span.checkbox-text, bp-confirm-dialog #bp-dialog .dialog-body .content .fields .field span.checkbox-text, bp-dialog #bp-dialog .dialog-body .content .fields .field span.checkbox-text {
              width: 100%; }
      #bp-dialog-overlay #bp-dialog .dialog-body .actions, bp-confirm-dialog #bp-dialog .dialog-body .actions, bp-dialog #bp-dialog .dialog-body .actions {
        background-color: white;
        display: flex;
        flex-direction: row-reverse;
        padding: 8px; }
        #bp-dialog-overlay #bp-dialog .dialog-body .actions button, bp-confirm-dialog #bp-dialog .dialog-body .actions button, bp-dialog #bp-dialog .dialog-body .actions button {
          width: 152px;
          height: 38px; }
      #bp-dialog-overlay #bp-dialog .dialog-body .dialogButtons, bp-confirm-dialog #bp-dialog .dialog-body .dialogButtons, bp-dialog #bp-dialog .dialog-body .dialogButtons {
        background-color: white;
        display: flex;
        flex-direction: row;
        padding: 8px; }
        #bp-dialog-overlay #bp-dialog .dialog-body .dialogButtons button, bp-confirm-dialog #bp-dialog .dialog-body .dialogButtons button, bp-dialog #bp-dialog .dialog-body .dialogButtons button {
          width: 152px;
          height: 38px; }

bp-link {
  text-decoration: none;
  margin-left: 18px; }
  bp-link .content {
    display: flex;
    flex-direction: row;
    align-items: center; }
    bp-link .content .link-label {
      font-size: 14px;
      color: steelblue; }
    bp-link .content.hazard {
      fill: red;
      color: red; }
      bp-link .content.hazard .link-label {
        color: darkred; }
      bp-link .content.hazard .icon svg {
        fill: darkred; }
    bp-link .content:hover {
      fill: red;
      color: red;
      text-decoration: underline;
      cursor: pointer; }
      bp-link .content:hover .link-label {
        color: red; }
      bp-link .content:hover .icon svg {
        fill: red; }
    bp-link .content .icon {
      padding-top: 6px; }
      bp-link .content .icon svg {
        fill: steelblue;
        width: 16px;
        height: 16px; }

bp-sidebar-link .active {
  background-color: #deeeff; }

#historyList {
  bottom: 0;
  position: fixed;
  margin-bottom: 50px;
  font-size: small;
  font-weight: bold;
  padding-left: 10px;
  margin-top: 250px;
  top: 0; }

bp-sidebar-history {
  width: 100%;
  background-color: aliceblue;
  border-top: 1px dotted darkgrey;
  font-weight: normal; }
  bp-sidebar-history .item {
    padding: 10px;
    font-size: small; }

bp-img.header-logo svg {
  height: 50px;
  flex-grow: 0;
  margin-right: 50px; }

bp-img.action-icon svg {
  width: 32px;
  height: 32px; }

bp-img.cancel-icon svg {
  margin: 4px 4px 0 4px;
  width: 12px;
  height: 12px;
  fill: red; }

bp-img.verify-icon svg {
  margin: 4px 4px 0 4px;
  width: 12px;
  height: 12px;
  fill: green; }

bp-img.button-icon svg {
  margin: 4px 4px 0 4px; }

bp-img.status-indicator svg {
  height: 48px;
  width: 48px;
  flex-shrink: 0;
  padding: 4px; }

bp-img.progress0 svg .level1 {
  fill: red; }

bp-img.progress0 svg .level2 {
  fill: white; }

bp-img.progress0 svg .level3 {
  fill: white; }

bp-img.progress0 svg .level4 {
  fill: white; }

bp-img.progress0 svg .level5 {
  fill: white; }

bp-img.progress20 svg .level1 {
  fill: red; }

bp-img.progress20 svg .level2 {
  fill: orange; }

bp-img.progress20 svg .level3 {
  fill: white; }

bp-img.progress20 svg .level4 {
  fill: white; }

bp-img.progress20 svg .level5 {
  fill: white; }

bp-img.progress40 svg .level1 {
  fill: orange; }

bp-img.progress40 svg .level2 {
  fill: orange; }

bp-img.progress40 svg .level3 {
  fill: orange; }

bp-img.progress40 svg .level4 {
  fill: orange; }

bp-img.progress40 svg .level5 {
  fill: white; }

bp-img.progress80 svg .level1 {
  fill: orange; }

bp-img.progress80 svg .level2 {
  fill: green; }

bp-img.progress80 svg .level3 {
  fill: green; }

bp-img.progress80 svg .level4 {
  fill: green; }

bp-img.progress80 svg .level5 {
  fill: white; }

bp-img.progress100 svg .level1 {
  fill: green; }

bp-img.progress100 svg .level2 {
  fill: green; }

bp-img.progress100 svg .level3 {
  fill: green; }

bp-img.progress100 svg .level4 {
  fill: green; }

bp-img.progress100 svg .level5 {
  fill: green; }

button {
  display: inline-flex;
  align-items: center;
  text-align: left;
  font-size: 16px;
  margin: 4px;
  padding-right: 8px;
  cursor: pointer;
  transition: background-color 0.15s ease-out, box-shadow 0.15s ease-out;
  background-color: #f9f9f9;
  border-radius: 7px;
  border: solid 1px #c98989;
  width: 180px;
  height: 50px; }
  button:hover {
    background-color: #ececec; }
  button:focus {
    outline: none; }
  button.disable {
    background-color: initial;
    cursor: default;
    opacity: 0.4; }
  button .invisible {
    display: none; }
  button .button-icon svg {
    width: 24px;
    height: 24px;
    margin-left: 2px;
    margin-right: 6px; }
  button .button-content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-right: 8px; }
  button .button-title {
    white-space: nowrap;
    color: black; }
  button .button-description {
    font-size: 12px;
    color: #999;
    white-space: nowrap; }

#account-details {
  padding-top: 4px;
  /*  padding-top: 4px;

  .title {
    display: flex;

    .heading {
      flex-grow: 1;
    }
  }

  .account-info {
    padding: 12px 0 6px 0;
  }

  .name-panel {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fbf9f9;
    padding: 8px;
    padding-left: 20px;
    flex-grow: 1;
    margin: 1px;

    .name {
      font-weight: 700;
    }

    .id {
      padding-left: 4px;
    }

    .label {
      font-size: 0.75em;
      opacity: 0.8;
    }
  }

  .info-panel {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #efefef;
    margin: 1px;
    margin-top: 12px;
    padding: 8px;
    padding-left: 20px;
    flex-grow: 1;
  }

  .text-label {
    font-size: 0.75em;
    opacity: 0.8;
  }

  .text-value {
    font-weight: 700;
  }
*/ }
  #account-details .toast {
    min-height: 36px;
    padding-left: 24px;
    color: #800000;
    fill: #800000; }
    #account-details .toast .content {
      display: flex;
      flex-direction: row;
      align-items: center; }
    #account-details .toast bp-img {
      padding: 2px;
      padding-top: 8px; }
      #account-details .toast bp-img svg {
        width: 16px;
        height: 16px; }
  #account-details .title {
    display: flex;
    flex-direction: row;
    padding: 8px;
    font-weight: 700; }
  #account-details .item {
    display: flex;
    flex-direction: column;
    padding: 8px; }
    #account-details .item .item-panel {
      background-color: #fbfbfb;
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      padding: 8px;
      flex-grow: 1;
      margin: 1px; }
    #account-details .item .label {
      font-size: 0.85em;
      opacity: 1;
      min-width: 75px; }
    #account-details .item .value {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      font-size: 0.75em;
      opacity: 0.8; }
      #account-details .item .value.email {
        font-size: 14px;
        flex-direction: row;
        color: steelblue;
        fill: steelblue; }
        #account-details .item .value.email bp-img.red svg {
          fill: red;
          width: 20px;
          height: 20px; }
        #account-details .item .value.email bp-img.green svg {
          fill: green;
          width: 18px;
          height: 18px; }
        #account-details .item .value.email bp-img svg {
          padding-top: 6px;
          width: 16px;
          height: 16px; }
      #account-details .item .value .flex-row {
        display: flex;
        flex-direction: row;
        padding: 8px;
        border-radius: 4px;
        border: 1px solid transparent; }
        #account-details .item .value .flex-row:hover {
          background-color: #edf2f7;
          border: 1px solid #a0d0ff;
          cursor: pointer; }

#admin-servers {
  padding-top: 4px; }
  #admin-servers table {
    padding: 2px;
    border: 1px solid #f7f7f7; }
    #admin-servers table tr {
      display: table-row;
      vertical-align: inherit;
      border-color: inherit; }
      #admin-servers table tr th {
        font-family: cursive;
        display: table-cell;
        vertical-align: inherit;
        font-weight: normal;
        text-align: left; }
      #admin-servers table tr td {
        display: table-cell;
        vertical-align: inherit; }
      #admin-servers table tr td, #admin-servers table tr th {
        padding: 0.5rem;
        text-align: left; }

#admin-server-details {
  padding-top: 4px; }
  #admin-server-details .title {
    display: flex; }
    #admin-server-details .title .heading {
      flex-grow: 1; }
  #admin-server-details .server-info {
    padding: 12px 0 6px 0; }
  #admin-server-details .name-panel {
    background-color: #fbfbfb;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 8px;
    padding-left: 20px;
    flex-grow: 1;
    margin: 1px; }
    #admin-server-details .name-panel .name {
      font-weight: 700; }
    #admin-server-details .name-panel .id {
      padding-left: 4px; }
    #admin-server-details .name-panel .item {
      padding: 12px; }
    #admin-server-details .name-panel .text-label {
      font-size: 0.75em;
      opacity: 0.8; }
  #admin-server-details .info-panel {
    background-color: #fbfbfb;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 8px;
    padding-left: 20px;
    flex-grow: 1;
    margin: 1px;
    margin-top: 12px; }
  #admin-server-details .text-label {
    font-size: 0.75em;
    opacity: 0.8; }
  #admin-server-details .text-value {
    font-weight: 500; }
  #admin-server-details .price {
    text-align: right;
    padding-right: 16px; }
  #admin-server-details td.hidden {
    padding: 0px; }
  #admin-server-details td.hiddenTop {
    border-top-width: 0px; }
  #admin-server-details button {
    display: inline-flex;
    align-items: center;
    text-align: left;
    font-size: 12px;
    margin: 2px;
    padding-right: 6px;
    cursor: pointer;
    transition: background-color 0.15s ease-out, box-shadow 0.15s ease-out;
    background-color: #f9f9f9;
    border-radius: 7px;
    border: solid 1px #c98989;
    width: 140px;
    height: 36px; }
  #admin-server-details button.active {
    background-color: lightsteelblue; }

#server-details {
  display: flex;
  flex-direction: column; }
  #server-details .details {
    display: flex;
    flex-direction: row;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fbfbfb;
    padding: 12px; }
    #server-details .details .server-info {
      flex-grow: 1; }
      #server-details .details .server-info .last-update {
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: flex-end; }
        #server-details .details .server-info .last-update .subtext {
          font-size: 12px; }
      #server-details .details .server-info .heading {
        font-weight: 700;
        font-size: 24px;
        padding-bottom: 12px; }
  #server-details .capabilities {
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fbfbfb;
    padding: 12px;
    margin-top: 32px; }
    #server-details .capabilities .title {
      font-size: 14px;
      font-weight: 700;
      padding-bottom: 12px; }
    #server-details .capabilities .items {
      display: flex;
      flex-direction: column; }
      #server-details .capabilities .items .item {
        font-size: 13px;
        width: 100%;
        border: 1px solid transparent; }
        #server-details .capabilities .items .item:hover {
          background-color: #edf2f7;
          border-radius: 4px;
          border: 1px solid #a0d0ff; }
        #server-details .capabilities .items .item > div {
          padding: 6px; }
        #server-details .capabilities .items .item > bp-link {
          padding: 6px; }
  #server-details .item {
    display: flex;
    flex-direction: row;
    /*    bp-link {
      .content {
        .icon {
          padding-top: 6px;
        }
      }
    }*/ }
    #server-details .item .title {
      font-weight: 500;
      min-width: 180px;
      font-size: 14px; }
    #server-details .item .value {
      min-width: 180px;
      font-size: 12px; }
  #server-details .controls {
    display: flex;
    flex-direction: column;
    flex-shrink: 1; }
    #server-details .controls bp-link .content .icon {
      padding-top: 6px; }

#server-players {
  display: flex;
  flex-direction: column; }
  #server-players .player-count {
    font-size: 14px; }
  #server-players .player-tiles {
    display: flex;
    flex-wrap: wrap; }
    #server-players .player-tiles .tile {
      flex-direction: row;
      width: 300px;
      background-color: #fafaff;
      border: 1px solid #007f00;
      border-radius: 4px;
      margin: 4px; }
      #server-players .player-tiles .tile.dead {
        border: 1px solid #af0000;
        background-color: #fde8e8; }
      #server-players .player-tiles .tile.offline {
        opacity: 0.7;
        background-color: #dce1ef;
        border: 1px solid #000000; }
      #server-players .player-tiles .tile > div {
        font-size: 14px;
        padding: 10px; }
        #server-players .player-tiles .tile > div .icon svg {
          width: 24px;
          margin-right: 6px; }
        #server-players .player-tiles .tile > div.steam-id {
          background-color: #f7f7f7;
          border-bottom: 1px solid #e7e7e7;
          font-size: 12px;
          display: flex;
          flex-direction: row;
          padding: 2px; }
        #server-players .player-tiles .tile > div.main-info {
          display: flex;
          flex-direction: row;
          align-items: center;
          border-bottom: 1px solid #e7e7e7; }
          #server-players .player-tiles .tile > div.main-info .header {
            display: flex;
            flex-direction: row;
            align-items: baseline; }
            #server-players .player-tiles .tile > div.main-info .header .name {
              flex-grow: 1;
              font-size: 1.2em;
              font-weight: 700; }
            #server-players .player-tiles .tile > div.main-info .header .id-header {
              font-weight: 700; }
            #server-players .player-tiles .tile > div.main-info .header .entity-id {
              font-size: 0.8em;
              padding-left: 4px; }
          #server-players .player-tiles .tile > div.main-info .stats {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            font-size: 0.95em; }
            #server-players .player-tiles .tile > div.main-info .stats .health {
              padding-left: 8px; }
        #server-players .player-tiles .tile > div.stats {
          display: flex;
          flex-direction: row; }
          #server-players .player-tiles .tile > div.stats .location {
            display: flex;
            flex-direction: row;
            align-items: center; }
            #server-players .player-tiles .tile > div.stats .location .position span {
              padding-right: 5px; }
            #server-players .player-tiles .tile > div.stats .location .rotation {
              display: flex;
              flex-direction: row;
              font-size: 0.8em; }
              #server-players .player-tiles .tile > div.stats .location .rotation span {
                padding-right: 5px; }
          #server-players .player-tiles .tile > div.stats .status {
            font-size: 0.8em; }
          #server-players .player-tiles .tile > div.stats .life-death {
            flex-grow: 1;
            font-size: 0.8em;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap; }
            #server-players .player-tiles .tile > div.stats .life-death .stat {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 3px 5px;
              border: 1px solid #e0e0e0;
              border-radius: 1000px;
              background-color: #ffffffa0;
              margin: 1px; }
              #server-players .player-tiles .tile > div.stats .life-death .stat bp-img svg {
                margin-top: 2px;
                margin-right: 2px;
                width: 12px; }
            #server-players .player-tiles .tile > div.stats .life-death .stat-title {
              width: 62px; }
            #server-players .player-tiles .tile > div.stats .life-death .stat-value {
              display: flex;
              justify-content: flex-end;
              width: 48px; }

#server-map {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /*  input {
    width: 90px;
    min-width: 90px;
  }*/ }
  #server-map .map-container {
    display: flex;
    flex-direction: row;
    height: 100%; }
  #server-map .sidebar {
    display: flex;
    flex-direction: column;
    width: 150px;
    min-width: 20%; }
  #server-map #game-world {
    flex-grow: 1;
    height: 100%; }
  #server-map .details {
    display: flex;
    flex-direction: row;
    padding: 12px; }
    #server-map .details .server-info {
      flex-grow: 1; }
      #server-map .details .server-info .last-update {
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: flex-end; }
        #server-map .details .server-info .last-update .subtext {
          font-size: 12px; }
      #server-map .details .server-info .heading {
        font-weight: 700;
        font-size: 24px;
        padding-bottom: 12px; }

#steam-link-verify {
  display: flex;
  flex-direction: column; }
  #steam-link-verify .validation {
    display: flex;
    flex-direction: row;
    color: red; }
    #steam-link-verify .validation bp-img svg {
      width: 16px;
      height: 16px; }
    #steam-link-verify .validation.success {
      color: green; }
      #steam-link-verify .validation.success svg {
        fill: green; }
    #steam-link-verify .validation svg {
      fill: red; }
    #steam-link-verify .validation .title {
      font-weight: 700;
      margin-right: 8px; }

#profile-index .title {
  display: flex;
  flex-direction: row; }

#profile-index .item {
  display: flex;
  flex-direction: row;
  padding: 8px; }
  #profile-index .item .item-panel {
    background-color: #fbfbfb;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 8px;
    flex-grow: 1;
    margin: 1px; }
  #profile-index .item .label {
    font-size: 0.85em;
    opacity: 1;
    min-width: 75px; }
  #profile-index .item .value {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-size: 0.75em;
    opacity: 0.8; }
    #profile-index .item .value .flex-row {
      display: flex;
      flex-direction: row;
      padding: 8px;
      border-radius: 4px;
      border: 1px solid transparent; }
      #profile-index .item .value .flex-row:hover {
        background-color: #edf2f7;
        border: 1px solid #a0d0ff;
        cursor: pointer; }

.auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px; }

.centered-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px; }

#woofles-logo {
  max-width: 150px;
  max-height: 150px;
  margin-bottom: 16px;
  margin-top: 32px; }

#forgot-password-link {
  display: flex;
  flex-direction: row;
  font-size: 10pt;
  padding: 5px; }
  #forgot-password-link:focus {
    outline: none; }

.link {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer; }
  .link:hover {
    color: #0056b3;
    text-decoration: underline; }

.alert dl, ol, ul {
  margin: 0; }

.alert {
  font-size: 13px;
  position: relative;
  padding: 1px;
  margin: 0;
  margin-left: 18px; }

.alert-danger {
  font-style: italic;
  color: #ff0000;
  background-color: unset;
  border: unset; }

.bottom-padding-12 {
  padding-bottom: 12px; }

.bottom-padding-32 {
  padding-bottom: 32px; }

.validate-email.message {
  font-weight: bold; }

.validate-email.token {
  font-style: italic; }

.validate-email.error {
  font-weight: bold;
  color: #ff0000; }

.full-width-flex {
  display: flex;
  flex-direction: row;
  align-items: center; }
